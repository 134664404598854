import Vue from "vue"
import VueI18n from "vue-i18n"
Vue.use(VueI18n)

//默认中文
const DEFAULT_LANG = "pt"

const locales = {
  pt: require("./i18n/pt.json")
}
const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales
})

export default i18n
