import Vue from "vue"
import "./plugins/vuetify"
import App from "./App.vue"
import axios from "axios"
import router from "./router/"
import store from "./store"
import "./registerServiceWorker"
import "roboto-fontface/css/roboto/roboto-fontface.css"
import "font-awesome/css/font-awesome.css"
import i18n from "./lang/lang"
import VueMask from "v-mask"
import VueCurrencyInput from "vue-currency-input"

Vue.use(VueMask)
Vue.use(VueCurrencyInput)
export const serverBus = new Vue()

// Vue.prototype.$http = axios;
// const token = localStorage.getItem("keyUser")
// if (token) {
//   debugger
//   Vue.prototype.$http.defaults.headers.common["Authorization"] = token
// }

function configurarTokenRequisicao() {
  axios.interceptors.request.use(async config => {
    const token = localStorage.getItem("setKeyUser")
    if (config.url === "/auth/login") {
      return config
    } else if (config.url.includes("https://viacep.com.br/ws")) {
      return config
    }
    config.headers.Authorization = `Bearer ${token}`
    return config
  })
}

axios.interceptors.request.use(
  function(config) {
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function(config) {
    return config
  },
  function(error) {
    if (
      error.request.responseType === "blob" &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf("json") != -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result)
          resolve(Promise.reject(error))
        }
        reader.onerror = () => {
          reject(error)
        }
        reader.readAsText(error.response.data)
      })
    }
    return Promise.reject(error)
  }
)

configurarTokenRequisicao()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app")
