import axios from "axios"
export class ServicoMenus {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Auth`
  }
  buscarMenuPorUsuario() {
    return axios({
      url: this.urlServico + `/getMenus`,
      method: "GET"
    })
  }
}
