<template>
  <v-navigation-drawer class="app--drawer" :mini-variant.sync="mini" app v-model="showDrawer" :width="drawWidth">
    <v-toolbar color="primary darken-1" dark>
      <img :src="image" height="45" alt="By Lust Lingerie" />
      <v-toolbar-title class="ml-0 pl-3">
        <span class="hidden-sm-and-down"><img :src="imageName" height="25" alt="By Lust Lingerie"/></span>
      </v-toolbar-title>
    </v-toolbar>
    <vue-perfect-scrollbar class="drawer-menu--scroll" :settings="scrollSettings">
      <v-list dense expand>
        <template v-for="item in menus">
          <v-list-tile
            :to="item.caminho"
            :href="item.caminho"
            ripple="ripple"
            :disabled="item.disabled"
            :target="item.target"
            rel="noopener"
            :key="item.name"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ item.desMenu }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import { ServicoMenus } from "../servicos/servicoMenus"
const servicoMenu = new ServicoMenus()
export default {
  name: "AppDrawer",
  components: {
    VuePerfectScrollbar
  },
  props: {
    expanded: {
      type: Boolean,
      default: true
    },
    drawWidth: {
      type: [Number, String],
      default: "260"
    },
    showDrawer: Boolean
  },
  data() {
    return {
      mini: false,
      menus: [],
      scrollSettings: {
        maxScrollbarLength: 160
      },
      image: require("@/assets/Logo-B_pequena.png"),
      imageName: require("@/assets/Nome.png")
    }
  },
  computed: {
    computeGroupActive() {
      return true
    },
    computeLogo() {
      return "/assets/Logo-B.png"
    },

    sideToolbarColor() {
      return this.$vuetify.options.extra.sideNav
    }
  },
  created() {
    servicoMenu.buscarMenuPorUsuario().then(
      res => {
        if (res.status === 200) {
          this.menus = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  },

  methods: {
    genChildTarget(item, subItem) {
      if (subItem.href) return
      if (subItem.component) {
        return {
          name: subItem.component
        }
      }
      return { name: `${item.group}/${subItem.name}` }
    }
  }
}
</script>

<style lang="stylus" scoped>
.app--drawer
  overflow: hidden
  .drawer-menu--scroll
    height: calc(100vh - 48px)
    overflow: auto
</style>
