<template>
  <v-app style="background-image: linear-gradient(#d9ada3, #eec3bb, #fad1c9)">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <router-view :key="$route.path"></router-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data: () => ({}),

  methods: {}
}
</script>
