import { AuthLayout, DefaultLayout, ChatLayout } from "@/components/layouts"

export const publicRoute = [
  { path: "*", component: () => import(/* webpackChunkName: "errors-404" */ "@/views/error/NotFound.vue") },
  {
    path: "/auth",
    component: AuthLayout,
    meta: { title: "Login" },
    redirect: "/auth/login",
    hidden: true,
    children: [
      {
        path: "login",
        name: "login",
        meta: { title: "Login" },
        component: () => import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue")
      }
    ]
  },
  {
    path: "/404",
    name: "404",
    meta: { title: "Not Found" },
    component: () => import(/* webpackChunkName: "errors-404" */ "@/views/error/NotFound.vue")
  },
  {
    path: "/500",
    name: "500",
    meta: { title: "Server Error" },
    component: () => import(/* webpackChunkName: "errors-500" */ "@/views/error/Error.vue")
  }
]

export const protectedRoute = [
  {
    path: "/",
    component: DefaultLayout,
    meta: { title: "Home", group: "apps", icon: "", requiresAuth: true },
    redirect: "/auth/login",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: { title: "Home", group: "apps", icon: "dashboard", requiresAuth: true },
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue")
      },
      {
        path: "/403",
        name: "Forbidden",
        meta: { title: "Access Denied", hiddenInMenu: true },
        component: () => import("@/views/error/Deny.vue")
      },
      {
        path: "/Produto",
        name: "Produto",
        meta: { title: "Produto", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/produto/Produto.vue")
      },
      {
        path: "/Produto/Cadastro",
        name: "cadastro-produto",
        meta: { title: "Cadastro de produto", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/produto/cadastro/CadastroProduto.vue")
      },
      {
        path: "/Produto/Cadastro/:id",
        name: "edit-produto",
        meta: { title: "Editar produto", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/produto/cadastro/CadastroProduto.vue")
      },
      {
        path: "/Produto/Visualizar/:id",
        name: "view-produto",
        meta: { title: "Visualizar produto", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/produto/view/ViewProduto.vue")
      },
      {
        path: "/Produto/Visualizar/:id",
        name: "unificar-produto",
        meta: { title: "Visualizar produto", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/produto/view/ViewProduto.vue")
      },
      {
        path: "/Linha",
        name: "Linha",
        meta: { title: "Linha", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/linha/Linha.vue")
      },
      {
        path: "/Linha/Cadastro",
        name: "CadastroLinha",
        meta: { title: "Cadastro linha", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/linha/cadastro/CadastroLinha.vue")
      },
      {
        path: "/Linha/Cadastro/:id",
        name: "edit-linha",
        meta: { title: "Editar linha", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/linha/cadastro/CadastroLinha.vue")
      },
      {
        path: "/Marca",
        name: "Marca",
        meta: { title: "Marca", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/marca/Marca.vue")
      },
      {
        path: "/Marca/Cadastro",
        name: "cadastro-marca",
        meta: { title: "Cadastro marca", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/marca/cadastro/CadastroMarca.vue")
      },
      {
        path: "/Marca/Cadastro/:id",
        name: "edit-marca",
        meta: { title: "Editar marca", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/marca/cadastro/CadastroMarca.vue")
      },
      {
        path: "/Usuarios",
        name: "Usuario",
        meta: { title: "Usuário", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/usuario/Usuario.vue")
      },
      {
        path: "/Usuarios/Cadastro",
        name: "cadastro-usuario",
        meta: { title: "Cadastro usuário", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/usuario/cadastro/Cadastro.vue")
      },
      {
        path: "/Usuarios/Editar",
        name: "edit-usuario",
        meta: { title: "Editar usuário", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/usuario/cadastro/Cadastro.vue")
      },
      {
        path: "/PerfisAcesso",
        name: "PerfisAcesso",
        meta: { title: "Perfis de acesso", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/perfisAcesso/PerfisAcesso.vue")
      },
      {
        path: "/PerfisAcesso/Visualizar/:id",
        name: "view-perfil",
        meta: { title: "Visualizar perfil de acesso", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/perfisAcesso/view/Visualizar.vue")
      },
      {
        path: "/PerfisAcesso/Editar/:id",
        name: "edit-perfil",
        meta: { title: "Editar perfil de acesso", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/perfisAcesso/cadastro/Cadastro.vue")
      },
      {
        path: "/PerfisAcesso/Cadastro",
        name: "cadastro-perfil",
        meta: { title: "Cadastro perfil", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/perfisAcesso/cadastro/Cadastro.vue")
      },
      {
        path: "/Cargo",
        name: "Cargo",
        meta: { title: "Cargo", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/cargo/Cargo.vue")
      },
      {
        path: "/Cargo/Cadastro",
        name: "cadastro-cargo",
        meta: { title: "Cadastro cargo", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/cargo/cadastro/CadastroCargo.vue")
      },
      {
        path: "/Cargo/Cadastro/:id",
        name: "edit-cargo",
        meta: { title: "Editar cargo", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/cargo/cadastro/CadastroCargo.vue")
      },
      {
        path: "/Funcionario",
        name: "Funcionario",
        meta: { title: "Funcionario", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/funcionario/Funcionario.vue")
      },
      {
        path: "/Funcionario/Cadastro",
        name: "cadastro-funcionario",
        meta: { title: "Cadastro funcionario", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/funcionario/cadastro/CadastroFuncionario.vue")
      },
      {
        path: "/Funcionario/Cadastro/:id",
        name: "edit-funcionario",
        meta: { title: "Editar funcionario", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/funcionario/cadastro/CadastroFuncionario.vue")
      },
      {
        path: "/Funcionario/Cadastro/:id",
        name: "view-funcionario",
        meta: { title: "Visualizar funcionario", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/funcionario/cadastro/CadastroFuncionario.vue")
      },
      {
        path: "/Fornecedor",
        name: "Fornecedor",
        meta: { title: "Fornecedor", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/fornecedor/Fornecedor.vue")
      },
      {
        path: "/Fornecedor/Cadastro",
        name: "cadastro-fornecedor",
        meta: { title: "Cadastro fornecedor", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/fornecedor/cadastro/CadastroFornecedor.vue")
      },
      {
        path: "/Fornecedor/Cadastro/:id",
        name: "edit-fornecedor",
        meta: { title: "Editar fornecedor", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/fornecedor/cadastro/CadastroFornecedor.vue")
      },
      {
        path: "/Fornecedor/Cadastro/:id",
        name: "view-fornecedor",
        meta: { title: "Visualizar fornecedor", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/fornecedor/cadastro/CadastroFornecedor.vue")
      },
      {
        path: "/CodigoBarras",
        name: "CodigoBarras",
        meta: { title: "Código de barras", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/codigoBarras/CodigoBarras.vue")
      },
      {
        path: "/CodigoBarras/Cadastro",
        name: "cadastro-codigoBarras",
        meta: { title: "Cadastro código de barras", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/codigoBarras/cadastro/CadastroCodigoBarras.vue")
      },
      {
        path: "/Praca",
        name: "Praca",
        meta: { title: "Praça", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/praca/Praca.vue")
      },
      {
        path: "/Praca/Cadastro",
        name: "cadastro-praca",
        meta: { title: "Cadastro praça", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/praca/cadastro/CadastroPraca.vue")
      },
      {
        path: "/Praca/View/:id",
        name: "view-praca",
        meta: { title: "Visualizar praça", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/praca/view/ViewPraca.vue")
      },
      {
        path: "/Praca/View/:id",
        name: "edit-praca",
        meta: { title: "Editar praça", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/praca/cadastro/CadastroPraca.vue")
      },
      {
        path: "/Entrada",
        name: "entrada",
        meta: { title: "Nota de entrada", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaEntrada/NotaEntrada.vue")
      },
      {
        path: "/Entrada/Cadastro",
        name: "add-entrada",
        meta: { title: "Nota de entrada", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaEntrada/cadastro/CadastroNotaEntrada.vue")
      },
      {
        path: "/Entrada/Visualizar/:id",
        name: "view-entrada",
        meta: { title: "Visualizar nota de entrada", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaEntrada/view/Visualizar.vue")
      },
      {
        path: "/Romaneio",
        name: "romaneio",
        meta: { title: "Romaneio", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/romaneio/Romaneio.vue")
      },
      {
        path: "/Romaneio/Cadastro",
        name: "cadastro-romaneio",
        meta: { title: "Cadastro romaneio", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/romaneio/cadastro/CadastroRomaneio.vue")
      },
      {
        path: "/Romaneio/Cadastro-v2",
        name: "cadastro-romaneio-v2",
        meta: { title: "Cadastro romaneio por referência", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/romaneio/cadastro/CadastroRomaneio_v2.vue")
      },
      {
        path: "/Romaneio/Editar/:id",
        name: "edit-romaneio",
        meta: { title: "Editar romaneio", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/romaneio/cadastro/CadastroRomaneio.vue")
      },
      {
        path: "/Romaneio/Visualizar/:id",
        name: "view-romaneio",
        meta: { title: "Visualizar romaneio", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/romaneio/view/Visualizar.vue")
      },
      {
        path: "/Romaneio/AlterarDatas/:id",
        name: "alterar-datas-romaneio",
        meta: { title: "Alterar datas do romaneio", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/romaneio/cadastro/AlterarDatas.vue")
      },
      {
        path: "/ConferenciaRomaneio",
        name: "romaneio-conferencia",
        meta: { title: "Romaneio Conferência", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/romaneioConferencia/RomaneioConferencia.vue")
      },
      {
        path: "/ConferenciaRomaneio/Editar/:id",
        name: "edit-romaneio-itens-devolvidos",
        meta: { title: "Editar romaneio - Itens devolvidos", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/romaneioConferencia/cadastro/EditarRomaneioComItensDevolvidos.vue")
      },
      {
        path: "/SaidaConsignada/:id?",
        name: "saidaConsignada",
        meta: { title: "Saída", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaSaida/Saida.vue")
      },
      {
        path: "/Saida/Cadastro",
        name: "cadastro-saida",
        meta: { title: "Cadastro nota de saída", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaSaida/cadastro/Cadastro.vue")
      },
      {
        path: "/Saida/Retorno/:id",
        name: "retorno",
        meta: { title: "Retorno", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaSaida/retorno/Retorno.vue")
      },
      {
        path: "/Saida/View/:id",
        name: "view-saida",
        meta: { title: "Visualização", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaSaida/view/Visualizar.vue")
      },
      {
        path: "/Saida/ViewRetorno/:id",
        name: "view-retorno",
        meta: { title: "Visualização", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaSaida/view/VisualizarRetorno.vue")
      },
      {
        path: "/Saida/AdicionarProdutos/:id",
        name: "adicionar-produto",
        meta: { title: "Adicionar produto", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaSaida/alterar/AdicionarProduto.vue")
      },
      {
        path: "/Saida/AlterarDatas/:id",
        name: "alterar-datas",
        meta: { title: "Alterar datas", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaSaida/alterar/AlterarDatas.vue")
      },
      {
        path: "/Saida/Unificar/:id",
        name: "unificar-saidas",
        meta: { title: "Unificar saídas", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/notaSaida/alterar/UnificarSaidas.vue")
      },
      {
        path: "/Vendedores",
        name: "Vendedores",
        meta: { title: "Vendedor", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/vendedor/Vendedor.vue")
      },
      {
        path: "/Vendedores/Cadastro",
        name: "cadastro-vendedor",
        meta: { title: "Cadastro de vendedor", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/vendedor/cadastro/Cadastro.vue")
      },
      {
        path: "/Vendedores/Visualizar/:id",
        name: "view-vendedor",
        meta: { title: "Visualizar vendedor", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/vendedor/view/Visualizar.vue")
      },
      {
        path: "/Vendedores/Editar/:id",
        name: "edit-vendedor",
        meta: { title: "Editar vendedor", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/vendedor/cadastro/Cadastro.vue")
      },
      {
        path: "/Vendedores/Unificar/:id",
        name: "unificar-vendedor",
        meta: { title: "Unificar vendedor", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/vendedor/unificar/Unificar.vue")
      },
      {
        path: "/Sacola",
        name: "Sacola",
        meta: { title: "Sacola", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/sacola/Sacolas.vue")
      },
      {
        path: "/Sacola/Cadastro",
        name: "cadastro-sacola",
        meta: { title: "Cadastro sacola", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/sacola/CadastroSacola.vue")
      },
      {
        path: "/Sacola/View/:id",
        name: "view-sacola",
        meta: { title: "Visualização", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/sacola/Visualizar.vue")
      },
      {
        path: "/Pedidos",
        name: "Pedidos",
        meta: { title: "Pedidos", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/pedido/Pedidos.vue")
      },
      {
        path: "/Pedidos/Cadastro",
        name: "cadastro-pedido",
        meta: { title: "Cadastro pedido", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/pedido/cadastro/CadastroPedido.vue")
      },
      {
        path: "/Pedidos/View/:id",
        name: "view-pedido",
        meta: { title: "Visualização", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/pedido/view/Visualizar.vue")
      },
      {
        path: "/Pedidos/Alterar/:id",
        name: "alterar-pedido",
        meta: { title: "Alterar pedido", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/pedido/alterar/AlterarPedido.vue")
      },
      {
        path: "/Sincronizacao",
        name: "Sincronizacao",
        meta: { title: "Sincronização", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/sincronizacao/Sincronizacoes.vue")
      },
      {
        path: "/Sincronizacao/View/:id",
        name: "view-sincronizacao",
        meta: { title: "Tentativas de sicronização", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/sincronizacao/VisualizarStatus.vue")
      },
      {
        path: "/Relatorios",
        name: "Relatorios",
        meta: { title: "Relatórios", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/relatorios/Relatorios.vue")
      },
      {
        path: "/Relatorios/estoque",
        name: "estoque",
        meta: { title: "Relatórios - Estoque", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/relatorios/estoque/Estoque.vue")
      },
      {
        path: "/Relatorios/saida-por-praca-e-data-retorno",
        name: "saida-por-praca-data-retorno",
        meta: { title: "Relatório - Saída por praça e data de retorno", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/relatorios/saidas/SaidasPorPracaEDataRetorno.vue")
      },
      {
        path: "/Relatorios/diferenca-romaneio-saidas",
        name: "diferenca-romaneio-saidas",
        meta: {
          title: "Relatório - Diferença entre itens no romaneio e em saídas",
          hiddenInMenu: true,
          requiresAuth: true
        },
        component: () => import("@/views/relatorios/romaneios/DiferencaItensRomaneioESaidas.vue")
      },
      {
        path: "/Relatorios/itens-nao-vendidos-por-romaneio",
        name: "itens-nao-vendidos-por-romaneio",
        meta: { title: "Relatório - Romaneio conferência", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/relatorios/romaneios/ItensNaoVendidosPorRomaneio.vue")
      },
      {
        path: "/Relatorios/Venda-por-periodo",
        name: "venda-por-periodo-referencia",
        meta: { title: "Relatório - Vendas por período e referência", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/relatorios/saidas/VendasPorPeriodoEReferencia.vue")
      },
      {
        path: "/Relatorios/Venda-por-periodo-linha",
        name: "venda-por-periodo-por-linha",
        meta: { title: "Relatório - Vendas por período", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/relatorios/saidas/VendasPorPeriodo.vue")
      },
      {
        path: "/Relatorios/Cobranca-por-praca",
        name: "cobranca-por-praca",
        meta: { title: "Relatório - Cobrança por praça", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/relatorios/saidas/CobrancaPorPraca.vue")
      },
      {
        path: "/AcertoPromotorVendas",
        name: "acerto-promotor-vendas",
        meta: { title: "Incluir - Acerto Promotor de vendas", hiddenInMenu: true, requiresAuth: true },
        component: () => import("@/views/acertoPromotorVendas/IncluirAcertoPromotorVendas")
      }
    ]
  },

  //list
  {
    path: "/cms",
    component: DefaultLayout,
    redirect: "/cms/table",
    meta: { title: "CMS", icon: "view_compact", group: "cms" },
    children: [
      {
        path: "/cms/table",
        name: "ListTable",
        meta: { title: "CMS Table" },
        component: () => import(/* webpackChunkName: "table" */ "@/views/list/Table.vue")
      }
    ]
  },

  //widgets
  {
    path: "/widgets",
    component: DefaultLayout,
    meta: { title: "Widget", icon: "widgets", group: "advance" },
    redirect: "/widgets/chart",
    children: [
      {
        path: "/widgets/chart",
        name: "ChartWidget",
        meta: { title: "Chart Widget" },
        component: () => import(/* webpackChunkName: "chart-widget" */ "@/views/widgets/Chart.vue")
      },
      {
        path: "/widgets/list",
        name: "ListWidget",
        meta: { title: "List Widget" },
        component: () => import(/* webpackChunkName: "list-widget" */ "@/views/widgets/List.vue")
      },
      {
        path: "/widgets/social",
        name: "SocialWidget",
        meta: { title: "Social Widget" },
        component: () => import(/* webpackChunkName: "social-widget" */ "@/views/widgets/Social.vue")
      },
      {
        path: "/widgets/statistic",
        name: "StatisticWidget",
        meta: { title: "Statistic Widget" },
        component: () => import(/* webpackChunkName: "statistic-widget" */ "@/views/widgets/Statistic.vue")
      }
    ]
  },

  //media
  {
    path: "/media",
    meta: { title: "Media", group: "apps", icon: "media" },
    name: "Media",
    props: route => ({ type: route.query.type }),
    component: () => import(/* webpackChunkName: "routes" */ `@/views/Media.vue`)
  },

  // chat app
  {
    path: "/chat",
    name: "Chat",
    component: ChatLayout,
    redirect: {
      path: "/chat/messaging"
    },
    meta: { title: "Chat", group: "apps", icon: "chat_bubble" },
    children: [
      {
        path: "/chat/messaging/:uuid?",
        name: "ChatMessaging",
        props: true,
        components: () => import(/* webpackChunkName: "chat-messaging" */ `@/components/chat/ChatMessaging.vue`)
      },
      {
        path: "/chat/contact/:uuid?",
        meta: {
          public: true
        },
        name: "ChatContact",
        components: () => import(/* webpackChunkName: "chat-contact" */ `@/components/chat/ChatContact.vue`)
      }
    ]
  },

  //mail app
  {
    path: "/mail",
    meta: {
      public: true
    },
    name: "Mail",
    component: () => import(/* webpackChunkName: "routes" */ `@/components/email/Layout.vue`),
    redirect: {
      path: "/mail/all"
    },
    children: [
      {
        path: "/mail/:mailType",
        meta: {
          public: true
        },
        name: "MailIndex",
        component: () => import(/* webpackChunkName: "routes" */ `@/components/email/List.vue`)
      },
      {
        path: "/mail/0/:uuid",
        meta: {
          public: true
        },
        name: "MailDetail",
        component: () => import(/* webpackChunkName: "routes" */ `@/components/email/Reply.vue`)
      }
    ]
  }
]
